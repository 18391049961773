import { jwtDecode } from 'jwt-decode';

import { COOKIE_KEYS } from '../../utils/Constants';
import CookieServices from '../../services/CookieServices';

class Authenticator {
    Check(shadowID) {
        try {
            if (!shadowID.authenticated) {
                return {
                    identity: false,
                    authenticated: false,
                    statusExists: false,
                    status: '',
                    resetSession: false,
                };
            }

            const accessKey = CookieServices.get(COOKIE_KEYS.TOKEN)
            const statusKey = CookieServices.get(COOKIE_KEYS.STATUS)

            if (accessKey === null) {
                return {
                    identity: false,
                    authenticated: false,
                    statusExists: false,
                    status: '',
                    resetSession: false,
                };
            }

            const decodedAccessKey = jwtDecode(accessKey);
            const identityMeta = decodedAccessKey.identity

            if (identityMeta.email === shadowID.email) {
                if (!statusKey) {
                    return {
                        identity: true,
                        authenticated: true,
                        statusExists: false,
                        status: '',
                        resetSession: false,
                    };
                }

                const decodedStatus = jwtDecode(statusKey);
                const accountStatus = decodedStatus.identity

                return {
                    identity: true,
                    authenticated: true,
                    statusExists: true,
                    status: accountStatus.status.toUpperCase(),
                    resetSession: false,
                }
            }

            return {
                identity: false,
                authenticated: false,
                statusExists: false,
                status: '',
                resetSession: true,
            };
        } catch (error) {
            return {
                identity: false,
                authenticated: false,
                statusExists: false,
                status: '',
                resetSession: true,
            };
        }

    }
}

export default new Authenticator();
