import { jwtDecode } from 'jwt-decode';

import { IDENTT } from "../utils/ActionsRegistry"
import CookieServices from "../services/CookieServices"
import StorageServices from '../services/StorageServices';
import { COOKIE_KEYS, STORAGE_KEYS } from "../utils/Constants"

const initialState = {
    error: null,
    acid: null,
    names: {
        first: null,
        last: null,
        display: null
    },
    email: null,
    status: null,
    pic_url: null,
    processing: false,
    authenticated: false,
}

export const idenTT_Reducer = (state = initialState, action: any) => {
    let decodedAccessKey: any = null
    let decodedStatusKey: any = null

    const accessKeyCookie = CookieServices.get(COOKIE_KEYS.TOKEN)
    const statusKeyCookie = CookieServices.get(COOKIE_KEYS.STATUS)

    switch (action.type) {
        case IDENTT.RESET_:
            return {
                ...state,
                error: null,
                acid: null,
                names: {
                    first: null,
                    last: null,
                    display: null
                },
                email: null,
                status: null,
                pic_url: null,
                processing: false,
                authenticated: false,
            }

        case IDENTT.PROCESSING:
            if (accessKeyCookie) {
                decodedAccessKey = jwtDecode(accessKeyCookie)
                let identityWP = decodedAccessKey.identity
                let displayNameWP = identityWP.first_name + ' ' + identityWP.last_name

                return {
                    ...state,
                    error: null,
                    acid: identityWP.uid,
                    names: {
                        first: identityWP.first_name,
                        last: identityWP.last_name,
                        display: displayNameWP
                    },
                    email: identityWP.email,
                    status: null,
                    pic_url: identityWP.photo_url,
                    processing: true,
                    authenticated: true,
                }
            }

            return {
                ...state,
                error: null,
                acid: null,
                names: {
                    first: null,
                    last: null,
                    display: null
                },
                email: null,
                status: null,
                pic_url: null,
                processing: true,
                authenticated: false,
            }

        case IDENTT.IDENTITY:
            decodedAccessKey = jwtDecode(action.response.access_key);
            CookieServices.set(COOKIE_KEYS.TOKEN, action.response.access_key, COOKIE_KEYS.OPTIONS)

            const identityMeta = decodedAccessKey.identity
            let displayName = identityMeta.first_name + ' ' + identityMeta.last_name

            return {
                ...state,
                error: null,
                acid: identityMeta.uid,
                names: {
                    first: identityMeta.first_name,
                    last: identityMeta.last_name,
                    display: displayName
                },
                email: identityMeta.email,
                status: null,
                pic_url: identityMeta.photo_url,
                processing: false,
                authenticated: true,
            }

        case IDENTT.BNF_STATUS:
            decodedStatusKey = jwtDecode(action.response.status_key)
            CookieServices.set(COOKIE_KEYS.STATUS, action.response.status_key, COOKIE_KEYS.OPTIONS)

            const benefactorStatus = decodedStatusKey.identity
            decodedAccessKey = jwtDecode(accessKeyCookie)

            let identityWP = decodedAccessKey.identity
            let displayNameWP = identityWP.first_name + ' ' + identityWP.last_name

            return {
                ...state,
                error: null,
                acid: identityWP.uid,
                names: {
                    first: identityWP.first_name,
                    last: identityWP.last_name,
                    display: displayNameWP
                },
                email: identityWP.email,
                status: benefactorStatus.status,
                pic_url: identityWP.photo_url,
                processing: true,
                authenticated: true,
            }

        case IDENTT.EXCEPTION:
            CookieServices.remove(COOKIE_KEYS.TOKEN)

            return {
                ...state,
                error: action.response,
                acid: null,
                names: {
                    first: null,
                    last: null,
                    display: null
                },
                email: null,
                status: null,
                pic_url: null,
                processing: false,
                authenticated: false,
            }

        case IDENTT.REVOKE:           
            CookieServices.remove(COOKIE_KEYS.TOKEN)
            CookieServices.remove(COOKIE_KEYS.STATUS)

            return {
                ...state,
                error: null,
                acid: null,
                names: {
                    first: null,
                    last: null,
                    display: null
                },
                email: null,
                status: null,
                pic_url: null,
                processing: false,
                authenticated: false,
            }

        default:
            return state;
    }
}