import { useDispatch } from "react-redux";
import { useRef, useState, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import Authenticator from "./Authenticator";
import { errorRoutes } from "../../routes/error";
import { useAppSelector } from "../../redux/hooks";
import { revokeIdentity } from "../../redux/idenTT_Actions";
import { StandardHeader } from "../../components/layouts/StandardHeader";
import { SideMenu } from "../../components/layouts/SideMenu";

export default function StandardGuard() {
    const dispatch: any = useDispatch();
    const identitySession = useAppSelector(state => state.identity);

    const location = useLocation()
    const currentLocation = location.pathname;

    const state = { from: currentLocation };
    const sessionState = Authenticator.Check(identitySession);

    const hasRevokedIdentity = useRef(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
        if (sessionState.resetSession && !hasRevokedIdentity.current) {
            dispatch(revokeIdentity());

            setShouldRedirect(true);
            hasRevokedIdentity.current = true;
        }
    }, [sessionState, dispatch]);

    if (shouldRedirect) {
        return <Navigate to="/auth/sign-in" replace />;
    }

    if (sessionState.resetSession) {
        /* 
         * Redux session state is authenticated
         * but cookies are not set.
         * Reset session and start all-over again
        */
        return
    } else if (!sessionState.authenticated) {
        return <Navigate to="/auth/sign-in" replace state={state} />;
    } else if (!sessionState.statusExists) {
        return <Navigate to={'/auth/identity/trust/gate'} replace />;
    } else {
        if (sessionState.status !== 'ACTIVE') {
            const accountStatusRoute: any = (
                errorRoutes.find((routeName) => routeName.name === 'BNF_STATUS_')
            )?.path

            return <Navigate to={accountStatusRoute} replace />;
        }
    }

    return (
        <div className="h-screen flex flex-col">

            <StandardHeader />

            <div className="flex-1 flex pt-16">
                <SideMenu location={currentLocation} />

                <main className="md:ml-16 flex-1 h-[calc(100vh-4rem)]">
                    <Outlet />
                </main>
            </div>
        </div>
    )
}