import React from "react"
import { Helmet } from "react-helmet"

import Config from "../../api/Config"
import { APPLICATION } from "../../utils/Constants"

export const Home = () => {
    const appTheme = APPLICATION.THEME
    const gradient = APPLICATION.GRADIENT

    return (
        <React.Fragment>
            <Helmet>
                <title>{Config.APP_NAME} Home</title>
            </Helmet>

            <div className="flex items-center justify-center h-full bg-[#a7e0ce] bg-gradient-to-br from-[#ffffff] via-[#ffffff] via-[40%] to-[#a7e0ce]">
                <h1 className={`text-${appTheme}-500`}>
                    Welcome  Home
                </h1>
            </div>
        </React.Fragment>
    )
}