import { SignIn } from "../views/auth/SignIn";
import { TrustGate } from "../views/auth/TrustGate";
import { Routes_Interface } from "./routesInterface";

export const identityRoutes: Array<Routes_Interface> = [
    {
        name: 'AUTH_SIGN_IN',
        path: "/auth/sign-in",
        element: <SignIn />,
        caseSensitive: true,
    },
]


export const trustRoutes: Array<Routes_Interface> = [
    {
        name: 'TRUST_GATE_',
        path: "/auth/identity/trust/gate",
        element: <TrustGate />,
        caseSensitive: true,
    },
]