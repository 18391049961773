import { AppConfig } from "../utils/Interfaces"

const Config: AppConfig ={
    APP_URL: process.env.REACT_APP_URL,
    APP_CORE: process.env.REACT_APP_CORE,
    APP_NAME: process.env.REACT_APP_NAME,
    API_KEY: undefined,
    logging: {
        level: 'error',
        remoteLogging: false
    },
    version: process.env.REACT_APP_VERSION,
}

export default Config