/*
* Application Constants
* */
export const APPLICATION = {
    THEME:      'green',
    GRADIENT:   '#A7E0CE',
    ERR_MSG:    'There was an error processing your request.'
}

export const STYLE = {
    W850        :{ maxWidth: '850px' },
    MAX_WIDTH   :{ maxWidth: '960px' },
    MARGIN_TOP  :{ marginTop: '64px' }
}

export const STORAGE_KEYS: any = {
    ENCRYPTION_KEY: '',
}

export const COOKIE_KEYS: any = {
    TOKEN:      '__utmcSNTC',
    STATUS:     '__utmcStqY',
    OPTIONS:    { path: '/', secure: true, sameSite: 'none' },
}

