import { Landing } from "../views/template/Landing";
import { Routes_Interface } from "./routesInterface";

export const patronRoutes: Array<Routes_Interface> = [
    {
        name: 'PATRON.LANDING_',
        path: "/",
        element: <Landing />,
        caseSensitive: true,
    },
]