import axios from 'axios';
import Moment from 'moment';
import {
    osName,
    osVersion,
    deviceType,
    engineName,
    browserName,
    engineVersion,
} from 'react-device-detect';

import Crypto from '../security/Crypto';
import { APPLICATION, COOKIE_KEYS } from './Constants';
import CookieServices from '../services/CookieServices';
import StorageServices from '../services/StorageServices';
import { standardRoutes } from '../routes/standard';

export function getAppTheme() {
    return APPLICATION.THEME
}

export function API_RouteReplace(apiRoute: string, findT: string, replaceT: any) {
    return apiRoute.replace(findT, replaceT)
}

export function DateFormating(dateString: any) {
    return Moment(dateString).format('Do ddd MMM YYYY')
}

export function DeviceInfo() {
    const osPart = `${osName} ${osVersion}`;
    const browserPart = `${browserName}/${engineName}`;

    return deviceType + ': ' + osPart + ' ' + browserPart + ' ' + engineVersion
}

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export function titleCase(str: string) {
    return str.toLowerCase().split(' ').map((word) => {
        return word[0].toUpperCase() + word.slice(1)
    }).join(' ')
}

export function encryptAndStoreCookie(key: string, data: any) {
    const encryptedData = Crypto.encryptDataUsingAES256(data)
    CookieServices.set(key, encryptedData, COOKIE_KEYS.OPTIONS)
}

export function encryptAndStoreLS(key: string, data: any) {
    // Local Storage data encryption and storage
    const strData = JSON.stringify(data);
    const encryptedData = Crypto.encryptDataUsingAES256(strData);

    StorageServices.setLocalStorage(key, JSON.stringify(encryptedData));
};

export function readDecryptAndParseLS(key: string) {
    const encryptedKeyString = StorageServices.getLocalStorage(key)
    const storageObject = JSON.parse(encryptedKeyString)

    const deStorageObject = Crypto.decryptDataUsingAES256(storageObject)
    return JSON.parse(deStorageObject)
}

export function getRandomObjectFromArray(array: any[]) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}

export function displayNameColoring(letter: string) {
    const colorGroups = [
        ['A', 'F', 'K', 'P', 'U'],
        ['B', 'G', 'L', 'Q', 'V'],
        ['C', 'H', 'M', 'R', 'W'],
        ['D', 'I', 'N', 'S', 'X'],
        ['E', 'J', 'O', 'T', 'Y', 'Z']
    ];

    const colors = [
        'bg-emerald-600',
        'bg-cyan-600',
        'bg-indigo-600',
        'bg-orange-500',
        'bg-fuchsia-600'
    ];

    const groupIndex = colorGroups.findIndex(group => group.includes(letter.toUpperCase()));

    // Default to gray if not starting with a letter
    return groupIndex !== -1 ? colors[groupIndex] : 'bg-gray-500';
}

export function generateUniqueKey(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function getStandardRoute(findByName: string) {
    return (standardRoutes.find(
        (routeName) => routeName.name === findByName)
    )?.path
}