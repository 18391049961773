import { useDispatch } from "react-redux";
import { useRef, useState, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import Authenticator from "./Authenticator";
import { errorRoutes } from "../../routes/error";
import { useAppSelector } from "../../redux/hooks";
import { revokeIdentity } from "../../redux/idenTT_Actions";

export default function TrustGuard() {
    const dispatch: any = useDispatch();
    const identitySession = useAppSelector(state => state.identity);

    const location = useLocation()
    const currentLocation = location.pathname;

    const state = { from: currentLocation };
    const sessionState = Authenticator.Check(identitySession);

    const hasRevokedIdentity = useRef(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
        if (sessionState.resetSession && !hasRevokedIdentity.current) {
            dispatch(revokeIdentity());

            setShouldRedirect(true);
            hasRevokedIdentity.current = true;
        }
    }, [sessionState, dispatch]);

    if (shouldRedirect) {
        return <Navigate to="/auth/sign-in" replace />;
    }

    if (sessionState.resetSession) {
        /* 
         * Redux session state is authenticated
         * but cookies are not set.
         * 
         * Reset session and start all-over again
        */
        return
    } else if (!sessionState.authenticated) {
        return <Navigate to="/auth/sign-in" replace state={state} />;
    } else if (sessionState.statusExists) {
        if (sessionState.status === 'ACTIVE') {
            return <Navigate to={'/home'} replace />;
        } else {
            const accountStatusRoute: any = (
                errorRoutes.find((routeName) => routeName.name === 'BNF_STATUS_')
            )?.path

            return <Navigate to={accountStatusRoute} replace />;
        }
    }

    return <Outlet />;
}