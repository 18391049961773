/* 
 * Actions Registry
*/

export const IDENTT = {
    RESET_:             'RESET',
    TOKEN:              'TOKEN',
    BNF_STATUS:         'BNF_STATUS',
    REVOKE:             'REVOKE',
    PROCESSING:         'PROCESSING',
    EXCEPTION:          'EXCEPTION',

    ID_PROC:            'ID_PROC',
    IDENTITY:           'IDENTITY',
}