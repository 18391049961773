import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from "react-dom/client";
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './redux/store';
import * as serviceWorker from './serviceWorker';
import { Loading } from './components/utils/Loading';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>

        <Provider store={store}>

            <PersistGate loading={<Loading />} persistor={persistor}>

                <App />

            </PersistGate>

        </Provider>

    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();
serviceWorker.unregister();
