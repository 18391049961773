import React from "react"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"

import Config from "../../api/Config"
import { APPLICATION } from "../../utils/Constants"
import { IdentityHeader } from "./IdentityHeader"

export const StandardHeader = () => {
    const appTheme = APPLICATION.THEME


    return (
        <React.Fragment>
            <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
                <link href="https://fonts.googleapis.com/css2?family=Silkscreen:wght@400;700&display=swap" rel="stylesheet" />
            </Helmet>

            <nav className="bg-white shadow fixed w-full h-16 top-0 left-0 px-4 z-10">
                <div className="max-w-full mx-auto md:px-4 px-2">
                    <div className="relative flex items-center justify-between h-16">
                        <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                            <div className="hidden sm:block">
                                <div className="flex space-x-4">
                                    <Link to="/home" className={`text-${appTheme}-500 mb-0 silkscreen-regular px-0 py-2 rounded-md`} aria-current="page">
                                        <span className="text-2xl tracking-wide">
                                            {Config.APP_NAME}
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className=" inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <div className="ml-3 relative">
                                <div>

                                    <IdentityHeader />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}