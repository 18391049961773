import { Helmet } from "react-helmet"
import React, { useEffect, useState } from "react"
import { ListboxButton, ListboxOption } from "@headlessui/react"

import Config from "../../api/Config"
import { ASSETS } from "../../api/Registry"
import { ListBox } from "../../lib/fragments/ListBox"
import HttpServices from "../../services/HttpServices"
import { classNames, generateUniqueKey } from "../../utils/Helpers"
import { Loading } from "../../components/utils/Loading"
import { ModuleHeader } from "../../lib/fragments/ModuleHeader"
import { ModuleBreadCrumb } from "../../lib/fragments/ModuleBreadCrumb"

export const AssetConfiguration = () => {
    const [state, setstate] = useState({
        status: 'pending',
        httpStatus: 200,
        data: {
            categories: null,
        },
        input: {
            search: ''
        },
    })

    const pageTitle = "Asset Configuration"
    const [activeCategory, setActiveCategory] = React.useState("");

    const handleClick = (value: string) => {
        if (value === activeCategory) {
            setActiveCategory("");
        } else {
            setActiveCategory(value);
        }
    }
    const breadCrumb = [
        { linkItem: false, title: "Asset Management" },
        { linkItem: false, title: "Configuration" },
    ]

    useEffect(() => {
        fetchAssetCategories()
    }, [])

    const fetchAssetCategories = async () => {
        let { status } = state
        let { httpStatus } = state

        let categories = null

        try {
            const response: any = await HttpServices.httpGet(ASSETS.ALL_CATEGORIES)
            httpStatus = response.status

            if (httpStatus === 200) {
                const payload: any = response.data.payload

                status = 'fulfilled'
                categories = payload.categories
            } else {
                status = 'rejected'
            }
        } catch (error) {
            status = 'rejected'
            httpStatus = 500
        }

        setstate((prevState) => ({
            ...prevState, status,
            httpStatus,
            data: {
                ...prevState.data,
                categories: categories,
            },
        }));
    }

    return (
        <>
            <Helmet>
                <title>Asset Categories - {Config.APP_NAME}</title>
            </Helmet>

            <div className="flex flex-col gap-x-3 px-3.5 w-full mx-auto py-3 md:h-full lg:h-full container md:max-w-4xl lg:max-w-4xl xl:max-w-5xl">
                {
                    state.status === 'rejected' ? (
                        <></>
                    ) : state.status === 'fulfilled' ? (
                        <div className="flex-grow w-full overflow-auto h-full flex flex-row">
                            <div className="flex-1 md:w-1/3 overflow-auto h-full pt-4">
                                <p className="text-base md:mb-3 text-green-600 text-wrap">
                                    Asset Categories
                                    <span className="block py-1 5 text-sm text-gray-600">
                                        Click on a category to view details
                                    </span>
                                </p>

                                <div className="">
                                    {
                                        state.data.categories.map((category: any) => {
                                            return (
                                                <div key={category.uuid} className="bg-inherit">
                                                    <h2 className="mb-0" id={category.uuid}>
                                                        <button
                                                            className={
                                                                classNames(
                                                                    activeCategory === category.uuid ? `text-green-700 bg-green-100 border-b border-green-200` : 'text-stone-600 border-b border-neutral-200',
                                                                    'group relative flex w-full align-middle items-center border-0 bg-white px-2 py-3.5 text-left text-base transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none'
                                                                )
                                                            }
                                                            type="button"
                                                            onClick={() => handleClick(category.uuid)}
                                                            aria-expanded="true"
                                                            aria-controls="collapseOne"
                                                        >
                                                            {category.name}
                                                            <span
                                                                className={`${activeCategory === category.uuid
                                                                    ? `rotate-[-180deg]`
                                                                    : `rotate-0 fill-[#212529]  dark:fill-white`
                                                                    } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </h2>

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="w-px bg-gray-300 mx-2"></div>

                            <div className="flex-2 w-2/3 h-full overflow-auto px-3 flex flex-col">
                                <div className="w-full flex-none">
                                    <div className="promotion flex flex-col relative z-[3] w-full pt-4">
                                        <div className="header-container opacity-100 flex flex-col justify-center flex-1">
                                            <ModuleBreadCrumb
                                                breadCrumb={breadCrumb}
                                            />

                                            <div className="header-title align-middle flex flex-nowrap title-transition">
                                                <h1 className="title md:text-lg text-base font-meidum tracking-wider leading-9 md:pb-2.5 pb-3 text-green-800">
                                                    {pageTitle}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="text-sm md:mb-5 text-gray-700 text-wrap">
                                        Organize and classify your assets into categories, types, departments, or purpose for efficient searching and reporting
                                    </p>
                                </div>

                                <div className="flex-grow flex flex-col h-full rounded-md md:flex-row md:space-x-4 justify-center border-2 border-gray-300 border-dashed">
                                    <div className="text-center flex w-full align-middle md:py-6 py-4 items-center">
                                        <div className="text-center flex w-full flex-col align-middle items-center">
                                            <svg
                                                className="mx-auto h-20 w-20 text-green-700"
                                                stroke="currentColor"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    opacity="0.5"
                                                    d="M20 6L3 6"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                ></path>
                                                <path
                                                    opacity="0.5"
                                                    d="M20 11L3 11"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                ></path>
                                                <path
                                                    opacity="0.5"
                                                    d="M11 16H3"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                ></path>
                                                <path
                                                    d="M14 18L17.5 15L21 18"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                ></path>
                                            </svg>

                                            <div className="text-s w-full ml-3 text-stone-600">
                                                <label
                                                    htmlFor="file-upload"
                                                    className="relative cursor-pointer rounded bg-white text-gray-600"
                                                >
                                                    <span>
                                                        Select an item from the list
                                                    </span>
                                                </label>
                                                <p className="pl-1"></p>
                                                <p className="text-xs text-stone-500">to view configured details</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex-grow w-full overflow-auto h-full flex flex-row py-4 justify-center align-middle items-center">
                            <Loading />
                        </div>
                    )
                }
            </div>
        </>
    )
}