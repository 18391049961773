import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { ListboxButton, ListboxOption } from "@headlessui/react"

import Config from "../../api/Config"
import { ASSETS } from "../../api/Registry"
import { ListBox } from "../../lib/fragments/ListBox"
import HttpServices from "../../services/HttpServices"
import { generateUniqueKey, getStandardRoute } from "../../utils/Helpers"
import { Loading } from "../../components/utils/Loading"
import { ModuleHeader } from "../../lib/fragments/ModuleHeader"

export const AssetRegistry = () => {
    const [state, setstate] = useState({
        httpStatus: 200,
        status: 'pending',
        selectedCategory: null,
        data: {
            categories: null,
        },
        input: {
            search: '',
            category: '00000000-0000-0000-0000-000000000001'
        },
    })

    const pageTitle = "Asset Management"
    const randomId = generateUniqueKey(17)
    const [bowWidth, setBoxWidth] = useState(0)
    const categoryRoute = getStandardRoute('ASSET.CONFIG_')

    const breadCrumb = [
        { linkItem: false, title: "Asset Management" },
        { linkItem: false, title: "Registry" },
    ]

    useEffect(() => {
        fetchAssetCategories()
    }, [])

    const fetchAssetCategories = async () => {
        let { status } = state
        let { httpStatus } = state

        let categories = null

        const allCategoriesEntry = {
            name: "All Categories",
            description: "Search from all available categories",
            uuid: "00000000-0000-0000-0000-000000000001"
        };

        try {
            const response: any = await HttpServices.httpGet(ASSETS.ALL_CATEGORIES)
            httpStatus = response.status

            if (httpStatus === 200) {
                const payload: any = response.data.payload

                status = 'fulfilled'
                categories = payload.categories

                categories.unshift(allCategoriesEntry);
            } else {
                status = 'rejected'
            }
        } catch (error) {
            status = 'rejected'
            httpStatus = 500
        }

        setstate((prevState) => ({
            ...prevState, 
            status,
            httpStatus,
            selectedCategory: allCategoriesEntry,
            data: {
                ...prevState.data,
                categories: categories,
            },
        }));
    }

    useEffect(() => {
        if (state.status === 'fulfilled') {
            const offsetWidth = document.getElementById(randomId).offsetWidth;
            setBoxWidth(offsetWidth)
        }
    }, [randomId, bowWidth]);

    const onChangeListBoxHandler = (e: any) => {
        let { data } = state
        let selectedCategory = data.categories.find(
            (obj: any) => obj.uuid === e
        )

        setstate((prev) => ({
            ...prev, 
            selectedCategory,
            input: {
                ...prev.input,
                category: e
            }
        }))
    }

    return (
        <>
            <Helmet>
                <title>Asset Registry - {Config.APP_NAME}</title>
            </Helmet>

            <div className="flex flex-col gap-x-3 px-3.5 w-full mx-auto py-3 container md:max-w-4xl lg:max-w-4xl xl:max-w-5xl">
                <div className="flex-grow w-full overflow-auto h-full flex flex-col">
                    <ModuleHeader
                        pageTitle={pageTitle}
                        breadCrumb={breadCrumb}
                        description={'Manage the entire lifecycle of your assets, from creation, to oversee maintenance, to decommissioning while ensuring streamlined tracking and management'}
                    />

                    {
                        state.status === 'rejected' ? (
                            <></>
                        ) : state.status === 'fulfilled' ? (
                            <>
                                <div className="w-full flex flex-row-reverse border-b pb-2 mb-2">
                                    <Link to={categoryRoute} className={`px-4 py-1.5 border-0 rounded text-sm text-green-600 hover:text-green-700 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-green-400`}>
                                        <span className="left-0 inset-y-0 flex items-center gap-x-3 align-middle justify-center w-full h-6">
                                            <i className="fa-light fa-cog fa-lg"></i>
                                            Asset Configuration
                                        </span>
                                    </Link>
                                </div>

                                <div className="flex md:align-middle md:items-center flex-col md:flex-row py-6 md:w-7/12 mx-auto w-full rounded-md gap-y-4">
                                    <div className="md:mx-auto w-full md:max-w-52 max-w-60 rounded">
                                        <ListBox
                                            width={bowWidth}
                                            value={state.input.search}
                                            eventHandler={(e: any) => onChangeListBoxHandler(e)}
                                            button={
                                                <>
                                                    <ListboxButton id={randomId} className="relative w-full bg-slate-200 border border-slate-200 rounded-md md:rounded-l-md md:rounded-r-none py-2.5 px-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm">
                                                        <span className="flex w-full items-center align-middle">
                                                            <div className="flex-grow flex items-center">
                                                                <span className="ml-3 block truncate">{state.selectedCategory.name}</span>
                                                            </div>

                                                            <span className="fa-light fa-chevron-down float-end"></span>
                                                        </span>

                                                    </ListboxButton>

                                                </>
                                            }
                                            options={
                                                <>
                                                    {state.data.categories.map((category: any) => (
                                                        <ListboxOption key={category.uuid} value={category.uuid} className="group relative cursor-default select-none py-2 pl-3 pr-9 text-slate-900 data-[focus]:bg-green-600 data-[focus]:text-white">
                                                            <div className="flex items-center">
                                                                <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                                                    {category.name}
                                                                </span>
                                                            </div>

                                                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-green-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                                                <span className="fa-light fa-check"></span>
                                                            </span>
                                                        </ListboxOption>
                                                    ))}
                                                </>
                                            }
                                        />
                                    </div>

                                    <div className="mx-auto flex-grow flex flex-row w-full">
                                        <input id="email-address" name="cred_email" type="text" autoComplete="off" required className={`appearance-none md:rounded-r-none md:rounded-l-none rounded-l-md relative block w-full px-3 py-2 md:py-2 border border-slate-200 placeholder-slate-500 text-slate-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm placeholder:text-sm`} placeholder="What can we help you find today?"
                                            value={state.input.search} />

                                        <div className="mx-auto max-w-20">
                                            <button className={`bg-green-500 relative flex justify-center py-2 px-3 border border-transparent text-sm rounded-r-md text-white hover:bg-green-600 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:bg-green-600`} type="submit">
                                                <span className="left-0 inset-y-0 flex items-center align-middle justify-center w-full h-6">
                                                    <i className="fa-light fa-magnifying-glass fa-lg"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="py-4">
                                <Loading />
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}