
/***************************
* Authentication API Routes
***************************/

export const AUTH = {
    SANCTUM_COOKIE:     'sanctum/csrf-cookie',
    SS1_BENEFACTORS:    '/api/v1/identity/auth/_/ss1/benefactor',
    BENEFACTOR_STATUS:  '/api/v1/identity/auth/_/benefactor/status',


    FETCH_IDENTITY:     '',

    SSO_BENEFICIARIES:  '/v1/identity/auth/firebase/_/sso/beneficiaries',
    PASSWD_RECOVERY:    '/v1/identity/auth/account/_/password-recovery',
    TOKEN_INVALIDATION: '/v1/identity/auth/account/_/access-token/invalidate',
    META_CHECK:         '/v1/identity/account/meta/check',
    PRE_META_01:        '/v1/identity/account/meta/identifier/check',
    PRE_META_03:        '/v1/identity/account/meta/artist_name/check',
    PRE_META_E:         '/v1/identity/account/meta/entity/check',
    ID_META_01:         '/v1/identity/account/meta/display_name',
    ID_META_02:         '/v1/identity/account/meta/msisdn',
    ARTIST_TYPES:       '/v1/identity/account/meta/artist/types',
    ID_META_03:         '/v1/identity/account/meta/artist',
    ENTITY_EXPANSION:   '/v1/identity/account/meta/entity/expansion',
    ENT_RE_EXPANSION:   '/v1/identity/account/meta/entity/expansion/retry',
    ENTITY_CONFIRMATION: '/v1/identity/account/meta/entity/confirmation',
};


export const TEMP = {
    SS2_BENEFACTORS:    '/api/temp/v1/identity/auth/_/ss2/benefactor',
}

/* 
 * Asset Management API Routes
*/
export const ASSETS = {
    ALL_CATEGORIES:     '/api/v1/assets-management/categories',
}