import React, { FC } from "react"
import { Link } from "react-router-dom"

import { generateUniqueKey } from "../../utils/Helpers"
import { ModuleHeaderProps } from "../../utils/Interfaces"

export const ModuleHeader: FC<ModuleHeaderProps> = ({
    breadCrumb, pageTitle, description
}) => {

    return (
        <React.Fragment>
            <div className="promotion flex flex-col relative z-[3] w-full pt-4">
                <div className="header-container opacity-100 flex flex-col justify-center flex-1">
                    <ul className="w-full flex flex-row align-middle items-center pb-4 text-xs text-slate-500">
                        <li>
                            <span className="h-5 flex gap-x-2 align-middle items-center">
                                <span className="fa-light fa-home"></span> Home
                            </span>
                        </li>

                        {breadCrumb.map(
                            (crumb: any) => (
                                <React.Fragment key={generateUniqueKey(13)}>
                                    <li className="ml-3 breadcrumb-item">
                                        <span className="fa-light fa-chevron-right text-gray-700"></span>
                                    </li>

                                    <li className="ml-3 breadcrumb-item">
                                        {
                                            crumb.linkItem ? (
                                                <Link to={crumb.url}>
                                                    <span className="lttr-spc">
                                                        {crumb.title}
                                                    </span>
                                                </Link>
                                            ) : (
                                                <span className="lttr-spc text-slate-600">
                                                    {crumb.title}
                                                </span>
                                            )
                                        }
                                    </li>
                                </React.Fragment>
                            )
                        )}
                    </ul>

                    <div className="header-title align-middle flex flex-nowrap title-transition">
                        <h1 className="title md:text-2xl text-xl font-meidum tracking-wider leading-9 md:pb-4 pb-3 text-green-800">
                            {pageTitle}
                        </h1>
                    </div>
                </div>

                <p className="text-sm md:mb-5 text-gray-700 text-wrap">
                    {description}
                </p>
            </div>
        </React.Fragment>
    )
}