import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom"
import { useEffect, useRef, useState } from "react"

import Authenticator from "./Authenticator";
import { useAppSelector } from "../../redux/hooks";
import { revokeIdentity } from "../../redux/idenTT_Actions";

export default function ErrorsGuard() {
    const dispatch: any = useDispatch();
    const identityState = useAppSelector(state => state.identity);

    const sessionState = Authenticator.Check(identityState);

    const hasRevokedIdentity = useRef(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
        if (sessionState.resetSession && !hasRevokedIdentity.current) {
            dispatch(revokeIdentity());

            setShouldRedirect(true);
            hasRevokedIdentity.current = true;
        }
    }, [sessionState, dispatch]);

    if (shouldRedirect) {
        return <Navigate to="/auth/sign-in" replace />;
    }

    if (sessionState.resetSession) {
        /* 
         * Redux session state is authenticated
         * but cookies are not set.
         * Reset session and start all-over again
        */
        return
    } else if (!sessionState.authenticated) {
        return <Navigate to="/auth/sign-in" replace />;
    } else if (!sessionState.statusExists) {
        return <Navigate to="/auth/identity/trust/gate" replace />;
    }

    return <Outlet />;
}