import React from "react"
import { Helmet } from "react-helmet"
import { useDispatch } from "react-redux"

import Config from "../../api/Config"
import { useAppSelector } from "../../redux/hooks"
import { Loading } from "../../components/utils/Loading"
import { benefactorStatusCheck } from "../../redux/idenTT_Actions"

export const TrustGate = () => {
    const dispatch: any = useDispatch();
    const identityState: any = useAppSelector(state => state.identity);

    React.useEffect(() => {
        statusCheckInitialization()
    }, [])

    const statusCheckInitialization = () => {
        if (!identityState.processing) {
            dispatch(benefactorStatusCheck())
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{Config.APP_NAME}</title>
            </Helmet>

            <div className="flex items-center justify-center min-h-screen md:p-6 bg-[#a7e0ce] bg-gradient-to-br from-[#ffffff] via-[#ffffff] via-[40%] to-[#a7e0ce]">
                <section className="md:w-[400px] w-full mx-auto py-8 z-[1]">
                    <div className="md:px-10 px-5">
                        <div className="w-full flex flex-col justify-center">
                            <div className="flex-grow pt-8">
                                <Loading />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    )
}