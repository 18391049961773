import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch } from "react-redux";

import Config from "../../api/Config";
import { useAppSelector } from "../../redux/hooks";
import { APPLICATION } from "../../utils/Constants";
import { Footer } from "../../components/layouts/Footer";
import { ss1BenefactorSignIn, resetIdentity } from "../../redux/idenTT_Actions";

export const SignIn = () => {
    const [state, setstate] = useState({
        showPassword: false
    })

    const [credentials, setCredentials] = React.useState({
        cred_email: "",
        cred_password: "",
    });

    const appTheme = APPLICATION.THEME
    const appGradient = APPLICATION.GRADIENT

    const dispatch: any = useDispatch();
    const identityState: any = useAppSelector(state => state.identity);

    React.useEffect(() => {
        /* 
        * On refresh or load of the Sign In page
        * reset the redux idenTT state
        */
        dispatch(resetIdentity())
    }, [])

    const togglePasswordVisibility = () => {
        if (!identityState.processing) {
            setstate(prev => ({ ...prev, showPassword: !prev.showPassword }));
        }
    };

    const handleIdentification = (e: any) => {
        e.preventDefault();
        const props = {
            auto: false,
            credentials: credentials,
        }

        dispatch(ss1BenefactorSignIn(props))
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{Config.APP_NAME} Sign In</title>
            </Helmet>

            <div className="flex items-center justify-center min-h-screen md:p-6 bg-[#a7e0ce] bg-gradient-to-br from-[#ffffff] via-[#ffffff] via-[40%] to-[#a7e0ce]">
                <section className="md:w-[400px] w-full mx-auto py-8 z-[1]">
                    <div className="md:px-10 px-5">
                        <header className="landing-header mb-3">
                            <div className="landing mb-0 text-left flex flex-col gap-y-2">
                                <h2 className={`odyssey text-left text-${appTheme}-500 text-2xl`}>{Config.APP_NAME}</h2>
                                <span className="text-sm text-left mt-0 mb-3 text-slate-700">Account Sign In</span>
                            </div>
                        </header>

                        <form className="space-y-3 shadow-none px-2 mb-3" onSubmit={handleIdentification}>
                            <div className="rounded-md shadow-none space-y-px flex flex-col gap-3">
                                <div className="mb-3 relative">
                                    <label htmlFor="email-address" className="sr-only">Email address</label>
                                    <input id="email-address" name="cred_email" type="email" autoComplete="off" required className={`appearance-none relative block w-full px-3 py-2 md:py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-${appTheme}-500 focus:border-${appTheme}-500 focus:z-10 sm:text-sm placeholder:text-sm`} placeholder="myemail@domain.com"
                                        value={credentials.cred_email}
                                        onChange={(e) => setCredentials({ ...credentials, cred_email: e.target.value })}
                                    />
                                </div>

                                <div className="w-full pb-5">
                                    <div className="w-full relative">
                                        <label htmlFor="password" className="sr-only">Password</label>
                                        <input id="password" name="cred_password" type={state.showPassword ? 'text' : 'password'} autoComplete="current-password" required className={`appearance-none relative block w-full px-3 py-2 md:py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-${appTheme}-500 focus:border-${appTheme}-500 focus:z-10 sm:text-sm placeholder:text-sm`}
                                            placeholder="********"
                                            value={credentials.cred_password}
                                            onChange={(e) => setCredentials({ ...credentials, cred_password: e.target.value })} />

                                        <div className="absolute inset-y-0 right-0 flex items-center w-10">
                                            {
                                                state.showPassword ? (
                                                    <span className={`fa-duotone fa-eye text-${appTheme}-600 fa-lg cursor-pointer`} onClick={togglePasswordVisibility}></span>
                                                ) : (
                                                    <span className={`fa-duotone fa-eye-slash text-${appTheme}-600 fa-lg cursor-pointer`} onClick={togglePasswordVisibility}></span>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                                {
                                    identityState.error &&
                                    <span className='invalid-feedback text-sm text-center block font-small text-red-600'>
                                        {identityState.error}
                                    </span>
                                }

                                <div className="mb-3 w-full flex justify-center">
                                    <button className={`bg-${appTheme}-500 relative w-auto flex justify-center py-1.5 px-8 border border-transparent text-sm rounded-md text-white hover:bg-${appTheme}-600 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:bg-${appTheme}-600`} type="submit">
                                        <span>
                                            {
                                                identityState.processing ? (
                                                    <>
                                                        <span className="left-0 inset-y-0 flex items-center align-middle justify-center w-full h-6">
                                                            <i className="fad fa-spinner-third fa-lg fa-spin"></i>
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        Sign In
                                                    </>
                                                )
                                            }
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div className="space-y-3 py-3 md:px-0 px-4">
                            <div className="flex items-center justify-between">
                                <div className="text-sm">
                                    <a href="/auth/forgot-password" className="text-center text-stone-600 hover:text-gray-900">
                                        <span className="font-small">
                                            Recover account?
                                        </span>
                                    </a>
                                </div>

                                <div className="text-sm">
                                    <a href="/" className="text-center text-stone-600 hover:text-gray-900">
                                        <span className="font-small">
                                            About
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <Footer />
                    </div>
                </section>
            </div>
        </React.Fragment>
    )
}