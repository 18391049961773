import { AUTH, TEMP } from "../api/Registry";
import { IDENTT } from "../utils/ActionsRegistry";
import AxiosServices from "../services/AxiosServices";
import HttpServices from "../services/HttpServices";

interface AuthProps {
    credentials?: any,
    locationState?: any,
}

const errorMessage = 'Something went wrong. Could not complete authentication'

export function ss1BenefactorSignIn(propsIn: AuthProps) {
    return async (dispatch: (arg0: { type: string; response: any; }) => void) => {
        try {
            const authProps = { ...propsIn }

            dispatch({
                type: IDENTT.PROCESSING,
                response: {
                    redirect: false,
                },
            });

            let formData = new FormData
            formData.append("email", authProps.credentials.cred_email)
            formData.append("password", authProps.credentials.cred_password)

            const authResp: any = await AxiosServices.httpPost(AUTH.SS1_BENEFACTORS, formData)
            const httpCode = authResp.status

            if (httpCode === 200) {
                dispatch({
                    type: IDENTT.IDENTITY,
                    response: authResp.data.payload,
                });
            } else {
                let httpError = authResp.response.status === 422
                    ? 'Fil all the required fields before proceeding'
                    : authResp.response.data.error.message

                dispatch({
                    type: IDENTT.EXCEPTION,
                    response: httpError,
                });
            }
        } catch (error) {
            dispatch({
                type: IDENTT.EXCEPTION,
                response: errorMessage,
            });
        }
    }
}

export function tempBenefactorSignUp(propsIn: AuthProps) {
    return async (dispatch: (arg0: { type: string; response: any; }) => void) => {
        try {
            const authProps = { ...propsIn }

            dispatch({
                type: IDENTT.PROCESSING,
                response: {
                    redirect: false,
                },
            });

            let formData = new FormData

            formData.append("email", authProps.credentials.cred_email)
            formData.append("last_name", authProps.credentials.last_name)
            formData.append("first_name", authProps.credentials.first_name)
            formData.append("password", authProps.credentials.cred_password)

            const authResp: any = await AxiosServices.httpPost(TEMP.SS2_BENEFACTORS, formData)
            const httpCode = authResp.status

            if (httpCode === 200) {
                dispatch({
                    type: IDENTT.IDENTITY,
                    response: authResp.data.payload,
                });
            } else {
                let httpError = authResp.response.status === 422
                    ? 'Fil all the required fields before proceeding'
                    : authResp.response.data.error.message

                dispatch({
                    type: IDENTT.EXCEPTION,
                    response: httpError,
                });
            }
        } catch (error) {
            dispatch({
                type: IDENTT.EXCEPTION,
                response: errorMessage,
            });
        }
    }
}

export const revokeIdentity = () => {
    return (dispatch: (arg0: { type: string; response: any; }) => void) => {
        dispatch({
            type: IDENTT.REVOKE,
            response: null,
        });
    }
}

export function resetIdentity() {
    return (dispatch: (arg0: { type: string; response: any }) => void) => {
        dispatch({
            type: IDENTT.RESET_,
            response: {
                redirect: false,
            },
        });
    }
}

export function benefactorStatusCheck() {
    return async (dispatch: (arg0: { type: string; response: any }) => void) => {
        try {
            dispatch({
                type: IDENTT.PROCESSING,
                response: {
                    redirect: false,
                },
            });

            const authResp: any = await HttpServices.httpGet(AUTH.BENEFACTOR_STATUS)
            const httpCode = authResp.status

            if (httpCode === 200) {
                dispatch({
                    type: IDENTT.BNF_STATUS,
                    response: authResp.data.payload,
                });
            } else {
                dispatch({
                    type: IDENTT.EXCEPTION,
                    response: errorMessage,
                });
            }
        } catch (error) {
            dispatch({
                type: IDENTT.EXCEPTION,
                response: errorMessage,
            });
        }
    }
}