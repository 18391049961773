import React from "react"
import { Helmet } from "react-helmet"
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

import Config from "../../api/Config"
import { useAppSelector } from "../../redux/hooks";
import { resetIdentity, tempBenefactorSignUp } from "../../redux/idenTT_Actions";

export const SignUp = () => {
    const [credentials, setCredentials] = React.useState({
        cred_email: "",
        first_name: "",
        last_name: "",
        cred_password: "",
    });

    const location = useLocation()
    const dispatch: any = useDispatch();
    const identityState: any = useAppSelector(state => state.identity);

    React.useEffect(() => {
        /* 
        * On refresh or load of the Sign In page
        * reset the redux idenTT state
        */
        dispatch(resetIdentity())
    }, [])

    const handleIdentification = (e: any) => {
        e.preventDefault();
        const props = {
            auto: false,
            credentials: credentials,
        }

        dispatch(tempBenefactorSignUp(props))
    };

    if (identityState.authenticated) {
        // Redirect to Identity Check
        console.log('AUrh c', 'authenticated');
        
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{Config.APP_NAME} Sign Up</title>
            </Helmet>

            <div className="flex items-center justify-center min-h-screen md:p-6 bg-[#a7e0ce] bg-gradient-to-br from-[#ffffff] via-[#ffffff] via-[40%] to-[#a7e0ce]">
                <section className="md:w-[430px] w-full mx-auto py-8 z-[1]">
                    <div className="md:px-10 px-5">
                        <header className="landing-header mb-3">
                            <div className="landing mb-0 text-left flex flex-col gap-y-2">
                                <h2 className="odyssey text-left text-green-500 text-2xl">{Config.APP_NAME}</h2>
                                <span className="text-sm text-left mt-0 mb-3 text-slate-700">Account Sign Up</span>
                            </div>
                        </header>

                        <form className="space-y-3 shadow-none px-2 mb-3" onSubmit={handleIdentification}>
                            <div className="rounded-md shadow-none space-y-px flex flex-col gap-3">
                                <div className="w-full">
                                    <label htmlFor="name-first" className="sr-only">First Name</label>
                                    <input id="name-first" name="first_name" type="text" autoComplete="off" required className="appearance-none relative block w-full px-3 py-2 md:py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm placeholder:text-sm" placeholder="First Name"
                                        value={credentials.first_name}
                                        onChange={(e) => setCredentials({ ...credentials, first_name: e.target.value })}
                                    />
                                </div>

                                <div className="w-full">
                                    <label htmlFor="name-last" className="sr-only">First Name</label>
                                    <input id="name-last" name="last_name" type="text" autoComplete="off" required className="appearance-none relative block w-full px-3 py-2 md:py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm placeholder:text-sm" placeholder="Last Name"
                                        value={credentials.last_name}
                                        onChange={(e) => setCredentials({ ...credentials, last_name: e.target.value })}
                                    />
                                </div>

                                <div className="w-full">
                                    <label htmlFor="email-address" className="sr-only">Email address</label>
                                    <input id="email-address" name="cred_email" type="email" autoComplete="off" required className="appearance-none relative block w-full px-3 py-2 md:py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm placeholder:text-sm" placeholder="myemail@domain.com"
                                        value={credentials.cred_email}
                                        onChange={(e) => setCredentials({ ...credentials, cred_email: e.target.value })}
                                    />
                                </div>

                                <div className="w-full pb-5">
                                    <label htmlFor="password" className="sr-only">Password</label>
                                    <input id="password" name="cred_password" type="password" autoComplete="current-password" required className="appearance-none relative block w-full px-3 py-2 md:py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm placeholder:text-sm"
                                        placeholder="********"
                                        value={credentials.cred_password}
                                        onChange={(e) => setCredentials({ ...credentials, cred_password: e.target.value })} />
                                </div>

                                {
                                    identityState.error &&
                                    <span className='w-full invalid-feedback text-center text-sm block font-small text-red-600'>
                                        {identityState.error}
                                    </span>
                                }

                                <div className="mb-3 w-full flex justify-center">
                                    <button className="bg-green-500 relative w-auto flex justify-center py-2 px-12 border border-transparent text-sm rounded-md text-white hover:bg-green-600 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:bg-green-600" type="submit">
                                        <span>
                                            {
                                                identityState.processing ? (
                                                    <>
                                                        <span className="left-0 inset-y-0 flex items-center w-5 h-5">
                                                            <i className="fad fa-spinner-third fa-lg fa-spin"></i>
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        Sign Up
                                                    </>
                                                )
                                            }
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div className="space-y-3 py-3 md:px-0 px-4">
                            <div className="flex items-center justify-between">
                                <div className="text-sm">
                                    <a href="/auth/forgot-password" className="text-center text-stone-600 hover:text-gray-900">
                                        <span className="font-small">
                                            Sign In?
                                        </span>
                                    </a>
                                </div>

                                <div className="text-sm">
                                    <a href="/" className="text-center text-stone-600 hover:text-gray-900">
                                        <span className="font-small">
                                            About
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <footer className="py-4 text-center text-sm text-gray-600 border-t-2 border-dashed md:mx-0 mx-4">
                            <p className="mb-2 px-0">© {new Date().getFullYear()}. {Config.APP_NAME}.</p>
                            <div className="flex justify-center space-x-4 py-2 w-full">
                                <a href="/terms" className="text-gray-500 hover:text-gray-900">Terms of Service</a>
                                <a href="/privacy" className="text-gray-500 hover:text-gray-900">Privacy Policy</a>
                                <a href="/help" className="text-gray-500 hover:text-gray-900">Help</a>
                            </div>
                        </footer>
                    </div>
                </section>
            </div>
        </React.Fragment>
    )
}