import { FC, Fragment, useEffect } from "react";
import { Label, Listbox, ListboxButton, ListboxOptions, Transition } from "@headlessui/react";

import { classNames } from "../../utils/Helpers";
import { ListBoxProps } from "../../utils/Interfaces";

export const ListBox: FC<ListBoxProps> = ({ label, value, width, button, options, eventHandler }) => {
    return (
        <Listbox value={value} onChange={event => eventHandler(event)}>
            {({ open }) => (
                <>
                    <Label className="block w-full text-sm text-gray-500">
                        {label}
                    </Label>

                    <div className="relative">
                        {button}

                        <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <div
                                className={
                                    classNames(
                                        width, 'fixed max-h-36 z-50 rounded shadow'
                                    )
                                }>
                                <ListboxOptions style={{ width: width }} className="fixed z-10 mt-1 bg-white shadow-lg max-h-36 w-full rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                    {options}
                                </ListboxOptions>
                            </div>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}