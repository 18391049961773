import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"

import imageAsset from '../../resources/images/7070464_3275437.svg'

export const PageNotFound = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Page Not Found</title>
            </Helmet >

            <div className="wrapper md:align-middle align-baseline w-full overflow-auto md:h-screen h-auto">
                <section className="gx-container md:h-screen h-auto rounded-md w-1024 flex items-center justify-center">
                    <div className="flex flex-col align-middle items-center w-full md:pb-0 pb-10">
                        <div className="md:w-96">
                            <img className="m-auto px-4 pt-6 block text-center mb-5 w-full" src={imageAsset} alt="404_page_not_found" />
                        </div>

                        <div className="max-w-sm md:max-w-md lg:max-w-lg text-center px-4">
                            <p className="text-red-500 md:text-2xl mb-3 text-lg">ERR_404: Page Not Found</p>
                            <p className="text-stone-600 mb-5 text-sm">
                                Look's like you're lost in the digital wilderness. Let us Guide you back
                            </p>

                            <div className="space-y-3">
                                <div className="flex items-center justify-between">
                                    <div className="text-sm m-auto ">
                                        <Link to="/home" className="text-center text-orange-600 hover:text-orange-700">
                                            <span className="flex items-center justify-between">
                                                <span>
                                                    Back to safety
                                                </span>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </React.Fragment >
    )
}