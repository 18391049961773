import { SignUp } from "../views/auth/SignUp";
import { Routes_Interface } from "./routesInterface";

export const temporaryRoutes: Array<Routes_Interface> = [
    {
        name: 'AUTH_SIGN_UP',
        path: "/temp/auth/sign-up",
        element: <SignUp />,
        guard: 'auth',
        caseSensitive: true,
    },
]
