import React from "react"
import { Link } from "react-router-dom"

import { generateUniqueKey } from "../../utils/Helpers"

export const ModuleBreadCrumb = ({ breadCrumb }) => {


    return (
        <React.Fragment>
            <ul className="w-full flex flex-row align-middle items-center pb-4 text-xs text-slate-500">
                <li>
                    <span className="h-5 flex gap-x-2 align-middle items-center">
                        <span className="fa-light fa-home"></span> Home
                    </span>
                </li>

                {breadCrumb.map(
                    (crumb: any) => (
                        <React.Fragment key={generateUniqueKey(13)}>
                            <li className="ml-3 breadcrumb-item">
                                <span className="fa-light fa-chevron-right text-gray-700"></span>
                            </li>

                            <li className="ml-3 breadcrumb-item">
                                {
                                    crumb.linkItem ? (
                                        <Link to={crumb.url}>
                                            <span className="lttr-spc">
                                                {crumb.title}
                                            </span>
                                        </Link>
                                    ) : (
                                        <span className="lttr-spc text-slate-600">
                                            {crumb.title}
                                        </span>
                                    )
                                }
                            </li>
                        </React.Fragment>
                    )
                )}
            </ul>
        </React.Fragment>
    )
}