import { AccountStatus } from "../views/errors/AccountStatus";
import { Routes_Interface } from "./routesInterface";

export const errorRoutes: Array<Routes_Interface> = [
    {
        name: 'BNF_STATUS_',
        path: "/auth/identity/account/status",
        element: <AccountStatus />,
        caseSensitive: true,
    },
]